export const FLAGS = {
  PLACEHOLDER: 'placeholder_flag',
  MYINFO_CLIENT_ID: 'alias-myinfo-client-id',
  REFRESHED_CARD_FACES: 'alias-link-refreshed-cards',
  OCBC_CARD_REPLACEMENT: 'alias-ocbc-card-replacement',
  DATADOG: 'alias-web-datadog',
  NFC_PREFERENCES: 'nfc-preference',
  LINKPASS_TERMS_OF_USE: 'linkpass-terms-of-use',
  PROFILE_VERIFICATION_REVAMP: 'alias-profile-verification-revamp',
  SHOW_MYINFO_UPDATE_BUTTON: 'sam-show-update-myinfo-button',
  MFA_SETTINGS: 'alias-mfa-settings',
  MYINFO_ENHANCEMENT: 'linkpass_alias_myinfo_feature',
  'HPB-INTEGRATION': 'hpb-integration-guard',
  ACC_RECOMMENDER: 'linkpass-acc-recommender',
};

export const FLAG_ATTRIBUTES = {
  platform: 'web',
};

export const FLAGS_VALUES = Object.keys(FLAGS).map((k) => FLAGS[k]);
